<style lang="scss">
#app {
    .o-analisis-page{
        .v-card__text{
            // padding-bottom: 0!important;
        }
    }
    .o-kit-pcr{
        .v-text-field .v-input__append-inner{
            //margin-top: 0;
        }
    }
}
.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) >
.v-input__control > .v-input__slot,
.v-text-field.v-text-field--enclosed .v-text-field__details {
    // min-height: 36px;
    min-height: initial;
}
.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) >
.v-input__control{
    min-height: 30px;
}
.c-red {
    color: #F00;
}
</style>
<template>
<!-- eslint-disable -->
<div class="container-fluid o-analisis-page">
    <v-snackbar v-model="snack.snackbar" :vertical="snack.vertical" :color="snack.color" :right="true" :top="true">
        {{ snack.txt }}

        <template v-slot:action="{ attrs }">
            <v-btn
                text v-bind="attrs"
                @click="snack.snackbar = false"
            >Cerrar</v-btn>
        </template>
    </v-snackbar>
    <v-card>
        <v-toolbar color="grey lighten-4" class="c-view-title hidden">
            <!--<toogleNavMenu ref="toogleNavMenu" />-->
            <div class="c-view-title-text" >
                <v-toolbar-title class="c-view-title-text__h1">
                    Sube tu archivo de PCR y analicemos tus resultados!
                </v-toolbar-title>
            </div>
            <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
            <div v-if="analizar">
                <v-row>
                  <v-col cols="12 flex align-center">
                    <!--
                    <v-btn class="mx-2" fab dark small color="primary">1</v-btn>
                    <h3>Información sobre tu análisis</h3>
                    -->
                    <h3>Bienvenido {{this.infoUser.nombreCliente}}</h3>
                  </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12 flex align-center">
                        <!--
                        <v-btn class="mx-2" fab dark small color="primary">1</v-btn>
                        <h3>Información sobre tu análisis</h3>
                        -->
                        <h3>Analiza tus resultados de PCR</h3>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6" md="5" class="relative o-kit-pcr">
                        <v-select
                        v-model="dataForm.equipoKit"
                        :items="equipoKit"
                        item-text="name"
                        item-value="id"
                        placeholder="Selecciona Tu Equipo"
                        label="Selecciona Tu Equipo"
                        @change="activaItemspocillos($event)"
                        outlined solo></v-select>
                    </v-col>
                    <!-- return-object agregar atributo para mandar siempre esl obejto y cambiarlo con ueva funcion -->
                </v-row>
                <v-row>
                    <v-col cols="6" md="5" class="relative o-file-search">
                        <v-file-input
                        v-model="dataForm.file"
                        placeholder="Carga tu archivo de datos de PCR"
                        :accept="accept_txt"
                        prepend-icon=""
                        :disabled="disabledFile"
                        @change="checkFile()"
                        outlined
                        :clearable="true"
                        class="o-file-search__input"
                        dense></v-file-input>
                        <v-icon class="c-icon o-file-search__icon"
                        >mdi-export</v-icon>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6" md="5" class="relative o-kit-pcr">
                        <v-select
                        v-model="dataForm.kitPcr"
                        :items="kitPCR"
                        item-text="name"
                        item-value="id"
                        placeholder="Selecciona"
                        label="KIT PCR"
                        @change="activarMuestras"
                        outlined solo></v-select>
                    </v-col>
                    <!-- return-object agregar atributo para mandar siempre esl obejto y cambiarlo con ueva funcion -->
                </v-row>
                <v-row v-if="this.infoUser.externo == 0">
                    <v-col cols="6" md="5">
                        <v-text-field
                            placeholder="Ingresa el numero de OT a analizar"
                            v-model="dataForm.OT"
                            type="number"
                            onkeydown="return event.keyCode !== 69"
                            min="1"
                            outlined
                        dense></v-text-field>
                    </v-col>
                    <v-col cols="3" md="3" style="text-align: left;">
                        <v-btn
                            color="primary c-btn--black"
                            @click="buscarOT()"
                            :loading="loadot"
                        >Revisar OT</v-btn>
                    </v-col>
                </v-row>
                <!-- <v-row>
                    <v-col cols="4" md="3">
                        <v-select v-model="dataForm.equipoKit" :items="equipoKit" item-text="name" item-value="id" placeholder="Selecciona" label="EQUIPO" @change="activarMuestras" outlined dense></v-select>
                    </v-col>
                </v-row> -->
                <!--
                <v-row>
                    <v-col cols="12" md="6" class="c-botones mb-4 text-left">
                        <v-btn depressed color="primary"
                            @click="analizarSamples()"
                            :loading="loadingResponse"
                            :disabled="activarAnalisis"
                        >ANALIZAR RESULTADOS</v-btn>
                    </v-col >
                </v-row>
                -->
                <v-row>
                    <v-col cols="12" class="flex align-center">
                        <!--
                        <v-btn class="mx-2" fab dark small color="primary">2</v-btn>
                        <h3>Información sobre tus muestras</h3>
                        -->
                        <h3>Ingresa tus muestras de forma manual</h3>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="2">
                        <dropdown

                            class="c-list-dropdown"
                            :close-on-click="true"
                        >
                            <template slot="btn" class="c-list-dropdown__btn">Acciones</template>
                            <template slot="body">
                                <ul>
                                <li>
                                    <dropdown :trigger="'hover'" :role="'sublist'" :align="'right'">
                                    <template slot="btn">Aplicar matriz</template>
                                    <template slot="body">
                                        <ul>
                                            <li v-for="(item, index) in matrices" :key="item.id" @click="aplicarmatriz(item)">
                                                {{ item.matriz }}
                                            </li>
                                        </ul>
                                    </template>
                                    </dropdown>
                                </li>
                                <li>
                                    <dropdown :trigger="'hover'" :role="'sublist'" :align="'right'">
                                    <template slot="btn">Sub-Matriz</template>
                                    <template slot="body">
                                        <ul>
                                            <li v-for="(item, index) in kitMatrices" :key="item.id" @click="aplicarKit(item)">
                                                {{ item.kit }}
                                            </li>
                                        </ul>
                                    </template>
                                    </dropdown>
                                </li>
                                  <li>
                                    <dropdown :trigger="'hover'" :role="'sublist'" :align="'right'">
                                      <template slot="btn">Estado</template>
                                      <template slot="body">
                                        <ul>
                                          <li v-for="(item, index) in deterioro" :key="item.id" @click="aplicarDeterioro(item)">
                                            {{ item.name }}
                                          </li>
                                        </ul>
                                      </template>
                                    </dropdown>
                                  </li>
                                </ul>
                            </template>
                        </dropdown>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12 c-table-analisys">
                        <template>
                            <v-simple-table fixed-header>
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="text-left table-th td-check">
                                                <v-checkbox color="primary" @click="checkAll($event)" v-model="check" :disabled="activeinput == 0"></v-checkbox>
                                            </th>
                                            <th class="text-left table-th td-name">
                                                POCILLO
                                            </th>
                                            <th class="text-left table-th td-code">
                                                CÓDIGO MUESTRA
                                            </th>
                                            <th class="text-left table-th td-matrix">
                                                MATRIZ
                                            </th>

                                            <th class="text-left table-th td-matrix">
                                                SUB-MATRIZ
                                            </th>
                                            <th class="text-left table-th td-percentage">
                                                ESTADO DE LA MUESTRA
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in sampleList" :key="item.name">
                                            <td class="text-start table-td td-check">
                                                <v-checkbox
                                                color="primary"
                                                v-model="item.checked"
                                                :disabled="(item.codesample) ? true : false"
                                                ></v-checkbox>
                                            </td>
                                            <td class="text-start table-td td-name td-pocillo">
                                                {{ item.pocillo }} {{( (item.namePocillo != '')
                                              ? (( dataForm.equipoKit == 2 ) ?'('+ item.namePocillo+')': '') : '')}}
                                            </td>
                                            <td class="text-start table-td td-code">
                                                <v-text-field
                                                    type="number"
                                                    onkeydown="return event.keyCode !== 69"
                                                    v-model="item.codesample"
                                                    placeholder="Ingresa Código"
                                                    @change="buscarMuestra(item,index)"
                                                    :disabled="activeinput == 0"
                                                    required
                                                    :error="item.color == 'red'"
                                                    :success="item.color == 'green'"
                                                    solo autocomplete="off"
                                                    outlined

                                                dense >
                                                </v-text-field>
                                            </td>
                                            <td class="text-start table-td td-matrix">
                                                <v-select
                                                    :items="matrices"
                                                    placeholder="Seleccionar matriz"
                                                    v-model="item.matriz_id"
                                                    :disabled="(item.matriz_id && item.info_muestra) ? true : false"
                                                    item-value="id"
                                                    item-text="matriz"
                                                    outlined
                                                    dense
                                                    @change="updatelist(item,$event)"
                                                ></v-select>
                                            </td>

                                            <td class="text-start table-td td-matrix">
                                                <v-select
                                                    :items="kitMatrices"
                                                    placeholder="Seleccionar kit"
                                                    v-model="item.kit"
                                                    :disabled="disableSelect(item)"
                                                    item-value="id"
                                                    item-text="kit"
                                                    outlined
                                                    dense
                                                    @change="updatematriz(item,$event)"
                                                ></v-select>

                                            </td>
                                            <td class="text-start table-td td-matrix">
                                                <v-select
                                                    :items="deterioro"
                                                    v-model="item.deteriorado"
                                                    :disabled="disableSelect(item)"
                                                    item-text="name"
                                                    placeholder="Seleccionar estado"
                                                    outlined
                                                    dense
                                                ></v-select>
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </template>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="c-botones text-left">
                        <v-btn depressed color="primary"
                            @click="analizarSamples();"
                            :loading="loadingResponse"
                        :disabled="activarAnalisis">ANALIZAR RESULTADOS</v-btn>
                    </v-col>
                </v-row>
            </div>
            <div v-else>
                <v-row>
                    <v-col cols="6" class="flex align-center">
                        <svg class="icon-svg" width="34px" height="34px" viewBox="0 0 446.688 446.688" style="--fillColor: #333; --bgColor: white;">
                            <use xlink:href="img/iconsSVG.svg#iconAnalisis"></use>
                        </svg>
                        <!--<h3 class="ml-3">Resultados SteriPLEX</h3>-->
                        <h3 class="ml-3">Resultados de tu análisis</h3>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" class=" o-table-analisys c-table-analisys">
                        <v-alert dense text type="success" v-if="publicsample == true">
                            Muestras publicadas <strong>con éxito</strong>
                            podrás revisarlo en <strong>Txa-lab</strong>
                        </v-alert>
                        <!-- cuando no hay ninguna muestra a publicar -->
                        <v-alert dense dismissible border="left" elevation="2" type="error" v-if="nadaApublicar == true">
                            No se ha ingresado muestras de <strong>TXA-LAB</strong>
                            para publicar
                        </v-alert>
                        <!-- alert cuando no se encuentran parametros que e estan enviando -->
                        <v-alert dense dismissible border="left" elevation="2" type="warning" v-for="item in parametroIndefinido" :key="item.pocillo+'_'+item.muestra+'_'+item.parametro">
                            El parámetro <strong>{{item.parametro}}</strong> de la muestra
                            <strong>{{item.muestra}}</strong> no se publicó.
                        </v-alert>
                        <!-- alert para muestras que tienen inhibicion como primer lugar -->
                        <v-alert dense dismissible border="left" elevation="2" type="error" v-for="item in muestrasNopublicadas" :key="'SI'+item.muestra">
                            La muestra <strong>{{item.codesample}}</strong> no se publicó por tener
                            <strong>{{item.Bac_1}}</strong>,
                            favor de volver a realizar el PCR.
                        </v-alert>
                        <!-- fin de alertas -->
                        <template>
                            <v-simple-table fixed-header dense>
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <!-- <th class="text-left table-th td-check">
                                            </th> -->
                                            <th class="text-left table-th td-name">
                                                POCILLO
                                            </th>
                                            <th class="text-left table-th td-code">
                                                MUESTRA
                                            </th>
                                            <th class="text-left table-th td-code">
                                                DESCRIPCIÓN
                                            </th>
                                            <th class="text-left table-th td-matrix">
                                                ORIGEN
                                            </th>
                                            <th class="text-left table-th td-matrix">
                                                MATRIZ
                                            </th>
                                            <th class="text-left table-th td-percentage">
                                                ESTADO
                                            </th>
                                            <th class="text-left table-th td-result">
                                                RESULTADO
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in sampleList" :key="item.name">
                                            <!-- <td class="text-start table-td td-check">
                                                <input type="checkbox"
                                                    id="checkbox"
                                                >
                                            </td> -->
                                            <td class="text-start table-td td-name">
                                              {{ item.pocillo }} {{( (item.namePocillo != '')
                                              ? (( dataForm.equipoKit == 2 ) ?'('+ item.namePocillo+')': '') : '')}}
                                            </td>
                                            <td class="text-start table-td td-code">
                                                <p v-if="item.codesample">{{item.codesample}}</p>
                                                <p v-else>--</p>
                                            </td>
                                            <td class="text-start table-td td-code">
                                                <p v-if="item.codesample">{{ item.info_muestra.descripcion }}</p>
                                                <p v-else>--</p>
                                            </td>
                                            <td class="text-start table-td td-matrix">
                                                <p v-if="item.matriz != 'Otro'">{{getOrigen(item)}}</p>
                                                <p v-else>Otro</p>
                                            </td>
                                            <td class="text-start table-td td-matrix">
                                                <p v-if="item.matriznombre">{{item.matriznombre}}</p>
                                                <p v-else>Otro</p>
                                            </td>
                                            <td class="text-start table-td td-percentage">
                                                <p>{{ item.deteriorado }}</p>
                                            </td>
                                            <td class="text-start table-td td-result">
                                                <v-expansion-panels v-model="panel"  multiple  class="c-dropdown">
                                                    <v-expansion-panel class="c-dropdown__panel">
                                                        <v-expansion-panel-header class="c-dropdown__header" >
                                                            <div class="c-dropdown__text"
                                                                v-for="(v) in arrayinicial"
                                                            >
                                                                <p class="c-dropdown__p"
                                                                    v-if="item[`Bac_${v}`] && item[`Bac_${v}`] != 'NA'"

                                                                >
                                                                <span :class="{ 'c-red': getclasebac(item,v) }">{{item[`Bac_${v}`]}} </span> <span :class="{ 'c-red': getclase(item) }">{{ getclase(item) }}</span> {{ getProbHtml(item,v) }}
                                                                </p>
                                                            </div>
                                                        </v-expansion-panel-header>
                                                        <v-expansion-panel-content class="c-dropdown__content">
                                                            <div class="c-dropdown__text"
                                                                v-for="(v) in arraycontinua"
                                                            >
                                                                <p class="c-dropdown__p"
                                                                    v-if="item[`Bac_${v}`] && item[`Bac_${v}`] != 'NA'"
                                                                >
                                                                    <span :class="{ 'c-red': getclasebac(item,v) }"> {{item[`Bac_${v}`]}} </span> <span :class="{ 'c-red': getclase(item) }">{{ getclase(item) }}</span> {{ getProbHtml(item,v) }}
                                                                </p>
                                                                <v-btn
                                                                    class="mx-2 outline-none"
                                                                    fab
                                                                    dark
                                                                    small
                                                                    color="primary"
                                                                    v-if="v === 1"
                                                                ><v-icon dark>mdi-plus</v-icon></v-btn>
                                                            </div>
                                                        </v-expansion-panel-content>
                                                    </v-expansion-panel>
                                                </v-expansion-panels>

                                                <!--<div class="td-dropdown">
                                                    <div class="dropdown"
                                                        v-for="(v) in [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15]"
                                                    >
                                                            <p v-if="item['Bac_'+v] && item['Bac_'+v] != 'NA'">
                                                                {{item['Bac_'+v]}} {{ getProbHtml(item['prob_final_'+v]) }}
                                                            </p>
                                                            <v-btn
                                                                class="mx-2 outline-none"
                                                                fab
                                                                dark
                                                                small
                                                                color="primary"
                                                                v-if="v === 1"
                                                            ><v-icon dark>mdi-plus</v-icon></v-btn>
                                                    </div>
                                                </div>-->

                                                <!-- <p v-if="item.prob_1 > 0">{{item.Bac_1}}</p>
                                                <p v-if="item.prob_2 > 0">{{item.Bac_2}}</p>
                                                <p v-if="item.prob_3 > 0">{{item.Bac_3}}</p>
                                                <p v-if="item.prob_4 > 0">{{item.Bac_4}}</p>
                                                <p v-if="item.prob_5 > 0">{{item.Bac_5}}</p> -->
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </template>
                    </v-col>
                </v-row>
                <v-row>

                    <v-col cols="12" class="text-right">
                        <v-btn depressed class="ma-2 c-btn c-btn--black" @click="volver()">
                            Volver
                        </v-btn>
                        <v-btn depressed
                            class="ma-2 c-btn c-btn--yellow"
                            @click="generapdf_ccdes()"
                        >Generar PDF</v-btn>
                        <v-btn depressed
                            class="ma-2 c-btn c-btn--primary"
                            @click="pulicarResult()"
                            :loading="loadingF"
                            :disabled="loadingF"
                        >PUBLICAR</v-btn>
                    </v-col>
                </v-row>
            </div>
        </v-card-text>
    </v-card>
    <!-- dialod -->
    <v-dialog v-model="dialog" max-width="400" persistent>
        <v-card>
            <v-card-title class="headline">
                Muestra con resultados
            </v-card-title>

            <v-card-text>
                La muestra ya se encuentra con resultados y publicada.
                ¿ Deseas publicar de nuevo ?
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn color="green darken-1" text @click="publicarMuestra">
                    Aceptar
                </v-btn>

                <v-btn color="green darken-1" text @click="nopublicarMuestra">
                    Cancelar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <detalleOt ref="detalleOt" />
</div>
</template>

<script>
/* eslint-disable */
import _ from 'lodash';
import { mapState,} from 'vuex';
import Treeselect from '@riophae/vue-treeselect';
import Service from '../services/apis';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
// import { delete } from 'vue/types/umd';
import Vue from 'vue';
import Dropdown from 'bp-vuejs-dropdown';
export default {
    components: {
        Dropdown,
        Treeselect,
        //toogleNavMenu: () => import('./toogleNavMenu'),
        detalleOt: () => import('./Analisis/DetalleOt'),
    },
    data: () => ({
        panel: [0,1],
        loadot:false,
        dialog: false,
        local: '',
        disabledFile: true,
        muestrasearch: null,
        loadingResponse: false,
        loadingF: false,
        activarAnalisis: true,
        dialogmultiple: false,
        valid: false,
        activeinput: 0,
        valueConsistsOf: 'LEAF_PRIORITY',
        analizar: true,
        accept: [],
        accept_txt: '',
        dataForm: {
          file : null,
          equipoKit:null,
        },
        matrices: [],
        kitMatrices: [],
        check: false,
        current_fecha: '',
        current_time: '',
        publicsample: false,
        nadaApublicar: false,
        infoUser: {
            email: '',
            nombre: '',
            direccionCliente: '',
            nombreCliente: '',
            clienteid: 0,
            config: '',
            externo: 0,
        },
        parametroIndefinido: [],
        muestrasNopublicadas: [],
        snack: {
            snackbar: false,
            txt: null,
            color: 'red',
            error: false,
            x: null,
            y: 'top',
        },
        kitEnsayos: [],
        kitPCR: [{
                id: 1,
                name: 'Steriplex Bacterias',
                id_ensayos: [1036,1037,1038,1039], // id ensayos [idali, idsuper, idmnip,idagua,idamb]
            },
            {
                id: 2,
                name: 'Steriplex hongos y levaduras',
                id_ensayos: 998, // id ensayos [idali, idsuper, idmnip,idagua,idamb]
            },
            {
                id: 3,
                name: 'Food Total',
                id_ensayos: [929,928,927], // id ensayos [idali, idsuper, idmnip,idagua,idamb]
            },
        ],
        equipoKit: [
            {
                id: 1,
                name: 'ChaiBio',
                pocillos: 16,
            },
            {
                id: 2,
                name: 'Aria',
                pocillos: 96,
            }
        ],
        deterioro: [{
                id: 'DETERIORADO',
                string: 'conDeterioro',
                name: 'DETERIORADO',
            },
            {
                id: 'NO DETERIORADO',
                string: 'sinDeterioro',
                name: 'NO DETERIORADO',
            },
        ],
        matrizKit: [{
                id: 12,
                label: 'Alimentos',
                children: [{
                        id: '12_1',
                        label: 'Aguas y aguas saborizadas',
                    },
                    {
                        id: '12_2',
                        label: 'Jugos pH <= 5',
                    },
                    {
                        id: '12_3',
                        label: 'Jugos pH > 5',
                    },
                    {
                        id: '12_4',
                        label: 'Bebidas carbonatadas',
                    },
                ],
            },
            {
                id: 7,
                label: 'Aguas',
            },
            {
                id: 9,
                label: 'Superficies',
            },
            {
                id: 8,
                label: 'Manipuladores',
            },
        ],
        namekit: null,
        sampleList: [{
                pocillo: 1,
                nomMatriz: null,
                codesample: '',
                matriz_id: 0,
                matriz: 'Otro',
                kit: null,
                Bac_1: null,
                Bac_2: null,
                Bac_3: null,
                Bac_4: null,
                Bac_5: null,
                prob_1: null,
                prob_2: null,
                prob_3: null,
                prob_4: null,
                prob_5: null,
                checked: false,
                icon: 'mdi-close',
                color: 'red',
                local: '',
                namePocillo: '1',
                deteriorado: null,
            },
            {
                pocillo: 2,
                nomMatriz: null,
                codesample: '',
                matriz_id: 0,
                matriz: 'Otro',
                kit: null,
                Bac_1: null,
                Bac_2: null,
                Bac_3: null,
                Bac_4: null,
                Bac_5: null,
                prob_1: null,
                prob_2: null,
                prob_3: null,
                prob_4: null,
                prob_5: null,
                checked: false,
                icon: 'mdi-close',
                color: 'red',
                local: '',
                namePocillo: '2',
              deteriorado: null,
            },
            {
                pocillo: 3,
                nomMatriz: null,
                codesample: '',
                matriz_id: 0,
                matriz: 'Otro',
                kit: null,
                Bac_1: null,
                Bac_2: null,
                Bac_3: null,
                Bac_4: null,
                Bac_5: null,
                prob_1: null,
                prob_2: null,
                prob_3: null,
                prob_4: null,
                prob_5: null,
                checked: false,
                icon: 'mdi-close',
                color: 'red',
                local: '',
                namePocillo: '3',
              deteriorado: null,
            },
            {
                pocillo: 4,
                nomMatriz: null,
                codesample: '',
                matriz_id: 0,
                matriz: 'Otro',
                kit: null,
                Bac_1: null,
                Bac_2: null,
                Bac_3: null,
                Bac_4: null,
                Bac_5: null,
                prob_1: null,
                prob_2: null,
                prob_3: null,
                prob_4: null,
                prob_5: null,
                checked: false,
                icon: 'mdi-close',
                color: 'red',
                local: '',
                namePocillo: '4',
              deteriorado: null,
            },
            {
                pocillo: 5,
                nomMatriz: null,
                codesample: '',
                matriz_id: 0,
                matriz: 'Otro',
                kit: null,
                Bac_1: null,
                Bac_2: null,
                Bac_3: null,
                Bac_4: null,
                Bac_5: null,
                prob_1: null,
                prob_2: null,
                prob_3: null,
                prob_4: null,
                prob_5: null,
                checked: false,
                icon: 'mdi-close',
                color: 'red',
                local: '',
                namePocillo: '5',
              deteriorado: null,
            },
            {
                pocillo: 6,
                nomMatriz: null,
                codesample: '',
                matriz_id: 0,
                matriz: 'Otro',
                kit: null,
                Bac_1: null,
                Bac_2: null,
                Bac_3: null,
                Bac_4: null,
                Bac_5: null,
                prob_1: null,
                prob_2: null,
                prob_3: null,
                prob_4: null,
                prob_5: null,
                checked: false,
                icon: 'mdi-close',
                color: 'red',
              local: '',
              namePocillo: '6',
              deteriorado: null,
            },
            {
                pocillo: 7,
                nomMatriz: null,
                codesample: '',
                matriz_id: 0,
                matriz: 'Otro',
                kit: null,
                Bac_1: null,
                Bac_2: null,
                Bac_3: null,
                Bac_4: null,
                Bac_5: null,
                prob_1: null,
                prob_2: null,
                prob_3: null,
                prob_4: null,
                prob_5: null,
                checked: false,
                icon: 'mdi-close',
                color: 'red',
              local: '',
              namePocillo: '7',
              deteriorado: null,
            },
            {
                pocillo: 8,
                nomMatriz: null,
                codesample: '',
                matriz_id: 0,
                matriz: 'Otro',
                kit: null,
                Bac_1: null,
                Bac_2: null,
                Bac_3: null,
                Bac_4: null,
                Bac_5: null,
                prob_1: null,
                prob_2: null,
                prob_3: null,
                prob_4: null,
                prob_5: null,
                checked: false,
                icon: 'mdi-close',
                color: 'red',
              local: '',
              namePocillo: '8',
              deteriorado: null,
            },
            {
                pocillo: 9,
                nomMatriz: null,
                codesample: '',
                matriz_id: 0,
                matriz: 'Otro',
                kit: null,
                Bac_1: null,
                Bac_2: null,
                Bac_3: null,
                Bac_4: null,
                Bac_5: null,
                prob_1: null,
                prob_2: null,
                prob_3: null,
                prob_4: null,
                prob_5: null,
                checked: false,
                icon: 'mdi-close',
                color: 'red',
              local: '',
              namePocillo: '9',
              deteriorado: null,
            },
            {
                pocillo: 10,
                nomMatriz: null,
                codesample: '',
                matriz_id: 0,
                matriz: 'Otro',
                kit: null,
                Bac_1: null,
                Bac_2: null,
                Bac_3: null,
                Bac_4: null,
                Bac_5: null,
                prob_1: null,
                prob_2: null,
                prob_3: null,
                prob_4: null,
                prob_5: null,
                checked: false,
                icon: 'mdi-close',
                color: 'red',
              local: '',
              namePocillo: '10',
              deteriorado: null,
            },
            {
                pocillo: 11,
                nomMatriz: null,
                codesample: '',
                matriz_id: 0,
                matriz: 'Otro',
                kit: null,
                Bac_1: null,
                Bac_2: null,
                Bac_3: null,
                Bac_4: null,
                Bac_5: null,
                prob_1: null,
                prob_2: null,
                prob_3: null,
                prob_4: null,
                prob_5: null,
                checked: false,
                icon: 'mdi-close',
                color: 'red',
              local: '',
              namePocillo: '11',
              deteriorado: null,
            },
            {
                pocillo: 12,
                nomMatriz: null,
                codesample: '',
                matriz_id: 0,
                matriz: 'Otro',
                kit: null,
                Bac_1: null,
                Bac_2: null,
                Bac_3: null,
                Bac_4: null,
                Bac_5: null,
                prob_1: null,
                prob_2: null,
                prob_3: null,
                prob_4: null,
                prob_5: null,
                checked: false,
                icon: 'mdi-close',
                color: 'red',
              local: '',
              namePocillo: '12',
              deteriorado: null,
            },
            {
                pocillo: 13,
                nomMatriz: null,
                codesample: '',
                matriz_id: 0,
                matriz: 'Otro',
                kit: null,
                Bac_1: null,
                Bac_2: null,
                Bac_3: null,
                Bac_4: null,
                Bac_5: null,
                prob_1: null,
                prob_2: null,
                prob_3: null,
                prob_4: null,
                prob_5: null,
                checked: false,
                icon: 'mdi-close',
                color: 'red',
              local: '',
              namePocillo: '13',
              deteriorado: null,
            },
            {
                pocillo: 14,
                nomMatriz: null,
                codesample: '',
                matriz_id: 0,
                matriz: 'Otro',
                kit: null,
                Bac_1: null,
                Bac_2: null,
                Bac_3: null,
                Bac_4: null,
                Bac_5: null,
                prob_1: null,
                prob_2: null,
                prob_3: null,
                prob_4: null,
                prob_5: null,
                checked: false,
                icon: 'mdi-close',
                color: 'red',
              local: '',
              namePocillo: '14',
              deteriorado: null,
            },
            {
                pocillo: 15,
                nomMatriz: null,
                codesample: '',
                matriz_id: 0,
                matriz: 'Otro',
                kit: null,
                Bac_1: null,
                Bac_2: null,
                Bac_3: null,
                Bac_4: null,
                Bac_5: null,
                prob_1: null,
                prob_2: null,
                prob_3: null,
                prob_4: null,
                prob_5: null,
                checked: false,
                icon: 'mdi-close',
                color: 'red',
              local: '',
              namePocillo: '15',
              deteriorado: null,
            },
            {
                pocillo: 16,
                nomMatriz: null,
                codesample: '',
                matriz_id: 0,
                matriz: 'Otro',
                kit: null,
                Bac_1: null,
                Bac_2: null,
                Bac_3: null,
                Bac_4: null,
                Bac_5: null,
                prob_1: null,
                prob_2: null,
                prob_3: null,
                prob_4: null,
                prob_5: null,
                checked: false,
                icon: 'mdi-close',
                color: 'red',
              local: '',
              namePocillo: '16',
              deteriorado: null,
            },
        ],
        muestrasAnalizar: [],
        arrayinicial : [1],
        arraycontinua : [2,3,4,5,6,7,8,9,10,11,12,13,14,15]
    }),
    computed: {
        ...mapState('Ensayos', ['matriz_peso', 'matrizhongos_peso']),
    },
    async created() {
        const info = {
            datoenv: 1,
        };
        const datosUser = await Service.apiToken('POST', 'get-userinfo', this.$ls.storage.token, info);
        // console.log(datosUser)
        this.infoUser.nombre = `${datosUser.data.user.name} ${datosUser.data.user.apellido}`;
        this.infoUser.email = datosUser.data.user.email;
        this.infoUser.direccionCliente = datosUser.data.cliente.direccion;
        this.infoUser.nombreCliente = datosUser.data.cliente.nombre;
        this.infoUser.clienteid = datosUser.data.cliente.id;
        this.infoUser.config = datosUser.data.cliente.config_extra;
        this.kitEnsayos = datosUser.ensayosCli;
        this.current_fecha = this.currentDate();
        let configCliente = this.infoUser.config != '' ? JSON.parse(this.infoUser.config) : '';
        if(configCliente!= ''){
            if(configCliente.modulos.txa){
              console.log(configCliente.modulos.txa.access)
                if(configCliente.modulos.txa.access == '0'){
                  this.infoUser.externo =1;
                  console.log(configCliente.modulos.txa)
                }
            }
        }
        //creamos el formato agrupando los ensayos
        var grouped = _.groupBy(this.kitEnsayos, 'name_ensayo');
        let kit = [];
        Object.keys(grouped).map((i) => {
            let ensayosid = [];
            let id = 0;
            grouped[i].map((D) => {
                id = D.ensayo.kit_steriplex
                ensayosid.push(D.ensayo.id);
            });
            kit.push({
                id: id,
                name: i,
                id_ensayos: ensayosid
            })
        });
        // descomentar en produccion
        this.kitPCR = kit;

        if(this.infoUser.externo == 0) {
          /// IMPORTANTE SE AGREGA MANUAL EL ID DE LOS ENSAYOS DE FOOD TOTAL PARA QUE APAREZCAN
          this.kitPCR.push(
            {
              id: 3,
              name: 'PCR nPLEX Duplex Ecol-Saur',
              parametros: ['Saur', 'Ecol'],
              id_ensayos: [/*142,184,222,755,1021,*/1130, 1129, 1128, 1127, 1126]
            },
            // {
            //     id: 4,
            //     name: 'PCR nPLEX Food Total',
            //     id_ensayos: [142,184,222,755,1021]
            // }
          );
          if (datosUser.data.cliente.id != 752) {
            this.kitPCR.push(
              {
                id: 4,
                name: 'PCR nPLEX Food Total',
                id_ensayos: [142, 184, 222, 755, 1021]
              }
            );
          }

        }
        ////se agregan solo monoplex y triplex solo para cliente norso
        let clientesduplex = [1,865];
        let clientemonoplex = [865];
        if( clientesduplex.includes(datosUser.data.cliente.id) ){
            this.kitPCR = [];
        }
        if(clientesduplex.includes(datosUser.data.cliente.id)){

            this.kitPCR.push(
                {
                    id: 3,
                    name: 'Monoplex Salmonella',
                    parametros:['Sal'],
                    id_ensayos: [1169,1170,1171,1172,1173]
                },
                {
                    id: 4,
                    name: 'Triplex Sal-Saur-Lmon',
                    parametros:['Sal','Saur','Lmon'],
                    id_ensayos: [1174,1175,1176,1177,1178]
                }
            );
        }
        switch (datosUser.data.cliente.id) {
            case 646:
                this.kitPCR = [];
                this.kitPCR.push(
                    {
                        id: 4,
                        name: 'Triplex Sal-Saur-Lmon',
                        parametros:['Sal','Saur','Lmon'],
                        id_ensayos: [1174,1175,1176,1177,1178]
                    }
                );
                break;
        }
        //temporal
        /*this.kitPCR.push(
            {
              id: 5,
              name: 'SteriPLEX Beverage Yeast & Mold DP',
              id_ensayos: [20]
            },
          );*/
        console.log('ensayoscliente',this.kitPCR)
        const datauser = {
            datoenv: 1,
        };
        const matrizlista = await Service.apiToken('POST', 'list-matriz',this.$ls.storage.token, datauser);
        this.matrices = matrizlista.response;
        // agregamos controles positivos y negativos como matrices
        this.matrices.push({id:'CP',matriz:'Control Positivo'});
        this.matrices.push({id:'CN',matriz:'Control Negativo'});

        const matrizKit = await Service.apiToken('POST', 'list-kit',this.$ls.storage.token, datauser);
        this.kitMatrices = matrizKit.response;
    },
    methods: {
        getProbHtml(item,pos){
            let result = '';
            if(item[`prob_final_${pos}`]){
                // result = (item[`prob_final_${pos}`] == 'NA' ) ? '' : `- ${item[`prob_final_${pos}`]} %`;
                switch (item[`prob_final_${pos}`]) {
                    case 'NA':
                        result = '';
                        break;
                    case 'NaN':
                        result = '0 %';
                        break;
                    default:
                        result = `- ${item[`prob_final_${pos}`]} %`
                        break;
                }
            }
            else{
                switch (item[`Bac_${pos}`]) {
                    case ' ':
                    case ' ':
                        // result = (item[`Prob_${pos}`] == true) ? 'Positivo' : 'Negativo';
                        result = (item[`Prob_${pos}`] == true) ? this.namekit : this.namekit;
                        break;
                    case 'Inhibido':
                        result = '';
                        break;
                    default:
                        result = (item[`Prob_${pos}`] == true) ? '- Positivo' : ' - Negativo';
                        break
                }
                // result = (item[`Prob_${pos}`] == true) ? '- Positivo' : ' - Negativo';
            }
            return result;
        },
        async generapdf_ccdes(){
            console.log(['info',this.dataForm,this.kitPCR]);
            const samplePubic = [];
            let idensa = 1;
            if (this.dataForm.kitPcr === 2) {
                idensa = this.kitPCR[1].id_ensayos
            }
            else{
                idensa = this.kitPCR[0].id_ensayos
            }
            const idensayo = {
                id: idensa,
            }
            const ensayodefault = await Service.apiToken('POST', 'get-info-ensayo', this.$ls.storage.token, idensayo);

            this.sampleList.forEach((element) => {
                // if (element.codesample !== '' && element.muestra_idres ) {
                    if (!element.info_ensayo_muestra) {
                        // element.matriz = '--';
                        element.info_ensayo_muestra = ensayodefault;
                    }
                    samplePubic.push(element);
                // }
            });
            const datasend = {
                string: samplePubic,
            }

            const PDFsteriplex = await Service.apiToken('POST', 'pdf-steriplex', this.$ls.storage.token, datasend);

            // const url = `http://ccdes.taag.mx/clases/reporte_steriplex2.php?parametros=${PDFsteriplex.data.id}&lang=es&infouser=${JSON.stringify(this.infoUser)}`;
            const url = `${process.env.VUE_APP_API_CC}clases/reporte_steriplex2.php?parametros=${PDFsteriplex.data.id}&lang=es&infouser=${JSON.stringify(this.infoUser)}`
            console.log(url)
            window.open(url, '_blank');
        },
        generarPDF() {
            const samplePubic = [];
            this.sampleList.forEach((element) => {
                //if (element.codesample !== '' && element.muestra_idres && element.Bac_1 !== 'Inhibicion') {
                    samplePubic.push(element);
                //}
            });
            return JSON.stringify(samplePubic);
        },
        async analizarSamples() {
            // recogemos datos
            // console.log('samplelist',this.sampleList);
            let pocilloMatriz = [];
            let data_resultados = [];
            let contvalidar = 0;
            this.current_time = this.currentTime();
            let kitid = this.dataForm.kitPcr;
            let kitinfo  = this.kitPCR.filter(function(element){
              return element.id == kitid;
            });
            this.sampleList.forEach(element => {
                // console.log('eke',element);
                let matrizConact = 'otro'
                let frecuencia_matriz = 'Otro';
                const consin_deterioro = element.deteriorado == 'DETERIORADO' ? 'conDeterioro' : 'sinDeterioro';
                if( (element.kit && !element.deteriorado) || (element.deteriorado && !element.kit)){
                    contvalidar+=1;
                }
                //obtenemos la frecuencia de matriz dependiendo de la muestra
                if(element.info_muestra){
                    switch (element.info_muestra.mp_pt) {
                        case 'mp':
                            frecuencia_matriz = 'MP';
                            break;
                        case 'mprima':
                            frecuencia_matriz = 'MP';
                            break;
                        case 'Pdeterminado':
                            frecuencia_matriz = 'PT';
                            break;
                        case 'Pterminado':
                            frecuencia_matriz = 'PT';
                            break;
                        default:
                            frecuencia_matriz = 'Otro'; // revisar
                            break;
                    }
                    //si existe la muestra pero es matriz de estos
                    switch (element.matriz_id) {
                        case 9:
                            frecuencia_matriz = 'Superficies';
                            break;
                        case 7:
                            // let matrizconcat = 'agua';
                            // consin_deterioro = 'sinDeterioro';
                            matrizConact = 'agua_sinDeterioro';
                            frecuencia_matriz = 'Aguas';
                            break;
                        case 8:
                            frecuencia_matriz = 'Manipuladores';
                            break;
                    }
                    if(element.kit && frecuencia_matriz=='PT'){
                        let matrizconcat = '';
                        switch (element.kit) {
                            case 1:
                                matrizconcat = 'agua'
                                break;
                            case 2:
                                matrizconcat = 'jugophmenos5'
                                break;
                            case 19:
                                matrizconcat = 'jugophmenos5'
                                break;
                            case 20:
                                matrizconcat = 'jugophmenos5'
                                break;
                            case 28:
                                matrizconcat = 'jugophmenos5'
                                break;
                            case 3:
                                matrizconcat = 'jugophmas5'
                                break;
                            case 4:
                                matrizconcat = 'carbonatada'
                                break;
                            case 10:
                                matrizconcat = 'carbonatada'
                                break;
                            case 14:
                                matrizconcat = 'jugophmenos5'
                                break;
                            default:
                                matrizconcat = ''
                                break;
                        }
                        matrizConact = matrizconcat+'_'+consin_deterioro;
                    }
                    // si no tieene kit pero es pt
                    if(!element.kit && frecuencia_matriz=='PT'){
                        frecuencia_matriz = 'Otro';
                    }
                }
                else{
                    switch (element.matriz_id) {
                        case 9:
                            frecuencia_matriz = 'Superficie';
                            break;
                        case 7:
                            frecuencia_matriz = 'Aguas';
                            break;
                        case 8:
                            frecuencia_matriz = 'Manipuladores';
                            break;
                        default:
                            break;
                    }
                }

                ///para clientes que no tienenacceso a slab tomamos 
                if(this.infoUser.externo == 1){
                    matrizConact = element.matriznombre ? element.matriznombre : 'otro';
                }

                const resultdata = {
                    cliente: this.infoUser.nombreCliente,
                    fecha: this.current_fecha,
                    hora: this.current_time,
                    kit: kitinfo[0].name,
                    ot: ((element.info_muestra) ? element.info_muestra.ot_id : '-'),
                    id_data_test: '',
                    id_muestra: ((element.info_muestra) ? element.info_muestra.id : '-'),
                    planta: ((element.local) ? element.local.nombre_local : '-'),
                    zona: ((element.info_muestra) ? element.info_muestra.linea : '-'),
                }
                data_resultados.push(resultdata)

                const pocillodata = {
                  pocillo: element.pocillo,
                  matriz: matrizConact,
                  M_freq: frecuencia_matriz,
                }
                pocilloMatriz.push(pocillodata)
            });
            // console.log(pocilloMatriz);
            // if (contvalidar > 0) {
            //     this.snack.snackbar = true;
            //     this.snack.color = 'red';
            //     this.snack.txt = 'Favor de completar la combinacion la matriz y/o deteriorado';

            //     return;
            // }
            if (this.dataForm.file === '' || this.dataForm.file === undefined || this.dataForm.file === null) {
                return;
            }
            this.loadingResponse = true;
            const data = new FormData();

            const archivo = this.dataForm.file;
            // const datosV = await Service.apiToken('POST', 'get-userinfo', this.$ls.storage.token);
            // data.append('user_id',  datosV.data.user.id);
            data.append('cliente_id',  this.infoUser.clienteid);
            data.append('data_result',  JSON.stringify(data_resultados));

            data.append('file', archivo, archivo.name);
            data.append('kit_pcr', this.dataForm.kitPcr);
            // data.append('equipo_pcr', this.dataForm.equipoKit);
            // data.append('', this.dataForm.equipoKit);
            data.append('equipo_pcr', this.dataForm.equipoKit);
            data.append('cliente_externo', this.infoUser.externo);
            // data.append('deterioro', this.dataForm.deterioro);
            data.append('matrizkit', JSON.stringify(pocilloMatriz));
            const dataRes = await Service.api('POST', 'multiplex', data);

            if (dataRes.data.status === 'error') {
                this.snack.snackbar = true;
                this.snack.color = 'red';
                this.snack.txt = 'Error al leer la estructura del archivo seleccionado';
                this.loadingResponse = false;
                return;
            }

            //console.log("arreglo",this.sampleList)
            if(this.dataForm.equipoKit == 2){
              //await this.activaItemspocillosAbecedario();
            }
            const dataAnalize = dataRes.data.data;
            console.log('dataAnalize',dataAnalize);
                /* eslint-disable no-param-reassign */
                dataAnalize.map((val) => {
                    this.sampleList.map((vval) => {
                        /* eslint-disable eqeqeq */
                        
                      //console.log(vval.namePocillo == val.Pocillo)
                        let pocillosample = (this.dataForm.kitPcr == 5) ? vval.pocillo : vval.namePocillo;
                        let pocillodata   = (this.dataForm.kitPcr == 5) ? val.Posicion : val.Pocillo;
                        
                        switch (this.dataForm.kitPcr) {
                            case 6:
                            //  vval.namePocillo = val.Pocillo; 
                            //  vval.namePocillo = val.namePocillo;
                             pocillosample = vval.pocillo;
                             pocillodata = val.Pocillo
                                break;
                        }
                        //  vval[`namePocillo`] = val.namePocillo;
                        // console.log(val)
                        // if (vval.pocillo == val.Pocillo) {
                        if (pocillosample == pocillodata/*vval.pocillo == val.Posicion*/) {
                            if(this.dataForm.kitPcr == 6){
                                vval.namePocillo = val.namePocillo;
                            }
                            
                            const dataAux = this.getBact(val);
                            // console.log('data',dataAux);
                            for (let index = 1; index < dataAux.length + 1; index += 1) {
                                vval.clase = dataAux[index - 1].clase;
                                if (dataAux[index - 1].nombre.includes('_')) {
                                    var aux = dataAux[index - 1].nombre.split('_');
                                    vval[`Bac_${index}`] = aux[0] + ' ' + aux[1];
                                } else {
                                    vval[`Bac_${index}`] = dataAux[index - 1].nombre;
                                }
                                vval[`Prob_${index}`] = dataAux[index - 1].value;
                                vval.outlier = val.Muestra_outlier;
                            }
                        }
                        return true;
                    });
                    return true;
                });
            if (this.dataForm.kitPcr == 3 || this.dataForm.kitPcr == 4  || this.dataForm.kitPcr == 6){ // si es food total
                this.analizarSamplesfood();
            }
            else{ //////////////////////////// si es HyL o Bacterias
                console.log("analizae")
                console.log(this.sampleList);
                this.analizarSamples2();
            }
        },
        analizarSamplesfood(){
            // this.arrayinicial = [2],
            // this.arraycontinua = [3,4,5,6,7,8,9,10,11,12,13,14,15]
            let kitid = this.dataForm.kitPcr;
            let kitinfo  = this.kitPCR.filter(function(element){
                return element.id == kitid;
            });
            this.namekit = kitinfo[0].name;
            let pocillosMostrar = [];
            this.sampleList.forEach((element) => {
                // console.log('elemet',element);
                element.foodtotal = true;

                
                // comprobamos del patogeno 2 a la 5 di todos son falsos
                let contpatogenosPos = 0;
                let pocilloactivo = 0;
                for (let index = 2; index < 6; index++) {
                    if (element[`Prob_${index}`] == true  ){
                        contpatogenosPos++;
                    }
                    if(element[`Bac_${index}`]){
                        pocilloactivo++;
                    }
                }
                                
                
                // si la probabilidad 1 es diferente de true es inhibido en clase
                if(( element.Prob_1=='INHIBIDO' || element.Prob_1=='PCR INHIBITION' ) && !element.control && contpatogenosPos==0){
                    element.clase = 'inhibido';
                    // element.Bac_1 = 'Inhibicion';
                    element.Bac_1 = 'Inhibido';
                    // convertimos sus Bac_1...n en NA para no mostrar
                    for (let index = 2; index < 6; index++) {
                        element[`Bac_${index}`] = 'NA';
                    }
                }else{
                    element.Bac_1 = contpatogenosPos == 0 ? ' ' : ' ';
                    element.Prob_1 =  (contpatogenosPos == 0) ? false : true;
                }

                // recorremos los patogenos que nos devuelve perl
                // y lo buscamos en patogenos del ensayo de la muestra
                for (let index = 2; index < 6; index++) {
                    if(element.info_ensayo_muestra){

                        let bacteria = element[`Bac_${index}`];
                        let infoparamtros = [];
                        if( Array.isArray(element.info_ensayo_muestra) ){
                            infoparamtros = element.info_ensayo_muestra[0].ensayo.patogeno_new_has_ensayos;
                        }else{
                            infoparamtros = element.info_ensayo_muestra.ensayo.patogeno_new_has_ensayos;
                        }
                        // console.log('infoparamtros',infoparamtros);
                        // let infoparamtros = element.info_ensayo_muestra.ensayo.patogeno_new_has_ensayos;
                        let encontrado = 0;
                        // console.log('ee',infoparamtros);
                        infoparamtros.forEach(paramensa => {
                            let nameparamEnsa =  paramensa.patogeno_new.ncorto;
                            if(nameparamEnsa === bacteria){
                                encontrado++;
                            }
                        });
                        if(encontrado === 0){
                            delete element[`Bac_${index}`];
                            delete element[`Prob_${index}`];
                            delete element[`prob_${index}`];
                        }

                    }
                    else{
                        /*
                        if(this.dataForm.kitPcr === 3){
                            console.log('entra a eliminar param');
                            // for (let index = 2; index < 6; index++) {

                                let bacteria = element[`Bac_${index}`]
                                let bac1 =  'Sal';
                                let bac2 =  'Lmon';
                                let encontrado = 0;

                                if(bacteria === bac1 || bacteria === bac2){
                                    encontrado++;
                                }
                                if(encontrado > 0){
                                    delete element[`Bac_${index}`];
                                    delete element[`Prob_${index}`];
                                    delete element[`prob_${index}`];
                                }



                            // }
                        }*/
                        //nuevo
                        let kitid = this.dataForm.kitPcr
                        let filtro  = this.kitPCR.filter(function(element){
                            return element.id == kitid;
                        });
                        // console.log('filtroparametros',filtro[0]);
                        if(filtro[0].parametros){

                            let bacteria = element[`Bac_${index}`];

                            let encontrado = 0;
                            filtro[0].parametros.forEach(paramensa => {
                                let nameparamEnsa =  paramensa;
                                if(nameparamEnsa === bacteria){
                                    encontrado++;
                                }
                            });
                            if(encontrado === 0){
                                delete element[`Bac_${index}`];
                                delete element[`Prob_${index}`];
                                delete element[`prob_${index}`];
                            }

                        }
                    }

                }
                ///validacion para mostrar solo los pocillos leidos
                // console.log(['pocillo',element.pocillo,pocilloactivo]);
                if(pocilloactivo > 0){
                    pocillosMostrar.push(element)
                }
            });
            this.sampleList = pocillosMostrar;
            this.loadingResponse = false;
            this.analizar = false;
            console.log('samplelist',this.sampleList);
        },
        analizarSamples2() {
            // verificamos si es bacterias o dehongo y levarudas 1= bcteria y 2 hongos
            //   let matrizPeso = this.matriz_peso;
            let matrizPeso = this.$store.state.Ensayos.matrizhongos_peso;
            if (this.dataForm.kitPcr === 2) {
                matrizPeso = this.$store.state.Ensayos.matrizhongos_peso;
            } else if (this.dataForm.kitPcr === 1) {
                matrizPeso = this.$store.state.Ensayos.matriz_peso;
            }
            matrizPeso = JSON.parse(JSON.stringify(matrizPeso));
            this.sampleList.forEach((element) => {
                let nombreMatriz = '--';
                if (element.matrizId) {
                    if (typeof (element.matrizId) === 'string') {
                        const matrizAlim = this.matrizKit[0].children;
                        const filtermat = matrizAlim.filter(
                            (matrizlist) => matrizlist.id === element.matrizId,
                        );
                        nombreMatriz = filtermat[0].label;
                    } else {
                        const filtermat = this.matrizKit.filter(
                            (matrizlist) => matrizlist.id === element.matrizId,
                        );
                        nombreMatriz = filtermat[0].label;
                    }
                }
                /* eslint-disable no-param-reassign */
                element.nomMatriz = nombreMatriz;
                /* eslint-disable no-param-reassign */
                //
                // var found = false;
                for (let index = 1; index < 16; index += 1) {
                    if (element[`Prob_${index}`] && element[`Prob_${index}`] > 0 && !false) {
                        let prob = element[`Prob_${index}`];
                        let result = [];

                        switch (element.matrizId) {
                            case '12_1':
                            default:
                                result = matrizPeso.filter((word) => word.Bacteria === element[`Bac_${index}`]);
                                /*
                                                                                if (result.length > 0 && element[`Prob_${index}`] > 20) {
                                                                                  prob = element[`Prob_${index}`] * 4;
                                                                                  // prob = element[`Prob_${index}`] * result[0].Aguas;
                                                                                }
                                                                                */
                                // if (result.length > 0) {
                                //     prob = element[`Prob_${index}`] * result[0].Aguas;
                                // }
                                prob = element[`Prob_${index}`];
                                break;
                            case '12_2':
                                result = matrizPeso.filter((word) => word.Bacteria === element[`Bac_${index}`]);
                                if (result.length > 0 && element[`Prob_${index}`] > 20) {
                                    prob = element[`Prob_${index}`] * result[0].Jugos1;
                                    prob = element[`Prob_${index}`] * 4;
                                }
                                break;
                            case '12_3':
                                result = matrizPeso.filter((word) => word.Bacteria === element[`Bac_${index}`]);
                                if (result.length > 0 && element[`Prob_${index}`] > 20) {
                                    prob = element[`Prob_${index}`] * result[0].Jugos2;
                                    prob = element[`Prob_${index}`] * 4;
                                }
                                break;
                            case '12_4':
                                result = matrizPeso.filter((word) => word.Bacteria === element[`Bac_${index}`]);
                                if (result.length > 0 && element[`Prob_${index}`] > 20) {
                                    prob = element[`Prob_${index}`] * result[0].Bebidas;
                                    prob = element[`Prob_${index}`] * 4;
                                }
                                break;
                        }
                        /* eslint-disable no-param-reassign */
                        if (isNaN(prob)){ //cuando no es numero
                            element[`prob_final_${index}`] = element[`Prob_${index}`];
                        }
                        else{
                            element[`prob_final_${index}`] = (prob > 100) ? 100 : prob;
                        }


                    }
                    if ( element[`Prob_${index}`] && isNaN(element[`Prob_${index}`]) ){
                        element[`prob_final_${index}`] = element[`Prob_${index}`];
                    }
                    else{
                        if (element[`Prob_${index}`] && element[`Prob_${index}`] <= 0) {
                            element[`prob_final_${index}`] = 0;
                        }
                    }

                }
                // console.log(element)
                let dataAux = this.getBact(element, '1');
                // console.log(dataAux)
                // if(element.pocillo==5){
                //     console.log('pos5',dataAux)
                // }
                if(element.outlier == 'SI'){
                    element.Bac_1 = 'Microorganismo no registrado';
                    element.Prob_1 = '100';
                    element.prob_final_1 = '100';
                    element.clase = 'Negativo';
                    for (let index = 2; index < dataAux.length + 1; index += 1) {
                        /* eslint-disable no-param-reassign */
                        element[`Bac_${index}`] = 'NA';
                        element[`Prob_${index}`] = 'NA';
                    }
                }else{

                  if(dataAux.length > 0){
                      const firstData = dataAux[0];
                      const data = [];

                      dataAux.map((v) => {
                          if (v.nombre !== firstData.nombre) {
                              data.push(v);
                          }
                          return true;
                      });
                      dataAux = _.orderBy(data, ['value'], ['desc']);

                      // PRIMER ELEMENTO DEL ARREGLO ( NUMERO MAS ALTO Y CALOR FINAL )
                      /* eslint-disable no-param-reassign */
                      element.Bac_1 = firstData.nombre;
                      element.Prob_1 = (firstData.value_final > 100) ? 100 : firstData.value_final;
                      element.prob_final_1 = (firstData.value_final > 100) ? 100 : firstData.value_final;

                      // ARREGLOS ACOMODADOS POR VALOR
                      let ciclos = dataAux.length + 1;
                      for (let index = 2; index <= ciclos; index += 1) {
                          /* eslint-disable no-param-reassign */
                          element[`Bac_${index}`] = dataAux[index - 2].nombre;
                          element[`Prob_${index}`] = dataAux[index - 2].value;
                          element[`prob_final_${index}`] = (dataAux[index - 2].value_final > 100) ? 100 : dataAux[index - 2].value_final; // agrega por que seguia conservado el valor antes del proceso
                      }
                  }
                }
            });

            // aqui hacemos la multiplicacion de cada bicho
            this.loadingResponse = false;
            this.analizar = false;
            console.log('samplelist',this.sampleList);
        },
        getBact(item, type = '0') {

            let arrAux = [];
            item.clase = (item.Clase) ? item.Clase : item.clase;
            if(item.clase && ( item.clase == 'Negativo' || item.clase == 'Vacio'  || item.clase.toLowerCase() == 'inhibido') ){

                // item.clase = (item.clase.toLowerCase() == 'inhibido') ? 'Negativo' : item.clase; // para que la clase inhivido se publique como negativo
                arrAux.push({
                        nombre: item.clase,
                        value: 100,
                        value_final: 100,
                        clase: (item.Clase) ? item.Clase : item.clase
                });
                
            }
            else if (item.clase && ( item.clase == 'Error_Perfil' || item.clase == 'Gris' ) ){
                switch (item.clase) {
                    case 'Error_Perfil':
                        arrAux.push({
                            nombre: 'Error de perfil, repetir reacción',
                            value: 100,
                            value_final: 100,
                            clase:  (item.Clase) ? item.Clase : item.clase //'Negativo' 
                        });
                        break;
                    case 'Gris':
                        arrAux.push({
                            nombre: 'Zona gris, repetir reacción',
                            value: 100,
                            value_final: 100,
                            clase: (item.Clase) ? item.Clase : item.clase
                        });
                        break;
                }

            }
            else{
                for (var i = 1; i < 16; i++) {
                    if ( item['Bac_' + i] && item['Bac_' + i] != 'NA' ) {
                        if (this.dataForm.kitPcr == 3 || this.dataForm.kitPcr == 4 || this.dataForm.kitPcr == 6){
                            arrAux.push({
                                nombre: item['Bac_' + i],
                                value: (item[`status_${i}`]) ? item[`status_${i}`] : false ,
                                value_final: (item[`status_${i}`]) ? item[`status_${i}`] : false ,
                                clase: (item.Clase) ? item.Clase : item.clase, // no tiene clase en food total
                                value_order: (item[`status_${i}`]) ? 1 : 0 ,
                            })
                        }
                        else{
                            arrAux.push({
                                nombre: item['Bac_' + i],
                                value: (isNaN(item[`Prob_${i}`])) ? item[`Prob_${i}`] : parseFloat( item[`Prob_${i}`] ),
                                value_final: ( item[`prob_final_${i}`] && isNaN(item[`prob_final_${i}`])) ? item[`Prob_${i}`] : (item[`prob_final_${i}`]) ? parseFloat(item[`prob_final_${i}`]) : 0,
                                clase: (item.Clase) ? item.Clase : item.clase,
                                value_order: ( item[`prob_final_${i}`] && isNaN(item[`prob_final_${i}`])) ? 0 : (item[`prob_final_${i}`]) ? parseFloat(item[`prob_final_${i}`]) : 0,
                            })
                        }
                    }
                }
            }

            if (type === '0') {

                arrAux = _.orderBy(arrAux, ['value'], ['desc']);
            } else if (type === '1') {
                // arrAux = _.orderBy(arrAux, ['value_final'], ['desc']);

                arrAux = _.orderBy(arrAux, ['value_order'], ['desc']);
            }
            return arrAux;
        },
        async pulicarResult() {
            const muestraPublicar = [];
            const muestraNopublicar = [];
            this.sampleList.forEach((element) => {


                // eliminamos una llave
                if(element.info_muestra){
                    delete element.info_muestra.ensayo_muestras;
                    delete element.info_ensayo_muestra.ensayo;
                }


                if (element.clase == 'Error_Perfil' || element.clase == 'Gris'){
                    if(element.codesample !== ''){
                        muestraNopublicar.push(element);
                    }
                }
                else{
                    if (element.codesample !== '' && element.muestra_idres) {
                        muestraPublicar.push(element);
                    }
                    // if (element.codesample !== '' && element.muestra_idres && element.Bac_1 === 'Inhibicion') {
                    //     muestraNopublicar.push(element);
                    // }
                }
            });
            //si no hay nada que publicar
            if (muestraPublicar.length == 0) {
                this.nadaApublicar = true;
                return;
            }
            this.nadaApublicar = false;
            this.muestrasNopublicadas = muestraNopublicar;
            const muestrasEnv = {
                muestraslist: muestraPublicar,
            };
            // return;
            this.loadingF = true;
            const publicarMuestra = await Service.apiToken('POST', 'publicar-muestras', this.$ls.storage.token, muestrasEnv);

            this.parametroIndefinido = publicarMuestra.indefinidos;
            this.publicsample = true;
            this.loadingF = false;
            this.gettop();
        },
        selectMatriz(e) {
            this.sampleList.forEach((element) => {
                if (element.checked === true) {
                    element.matrizId = e.id;
                }
            });
        },
        checkAll() {
            if (this.check === true) {
                this.sampleList.forEach((element) => {
                    element.checked = true;
                });
            } else {
                this.sampleList.forEach((element) => {
                    element.checked = false;
                });
            }
        },
        async buscarMuestra(item, index) {
            // console.log('busca muestra');
            if (item.codesample === '') {
                this.sampleList.forEach((element) => {
                    if (element.pocillo === item.pocillo) {
                        element.icon = 'mdi-close';
                        element.color = 'red';
                        element.codesample = '';
                        element.muestra_idres = '';
                        element.matriz_id = 0;
                        element.matriznombre = '';
                        element.deteriorado = '';
                        element.kit = 0;
                        element.checked = false;
                    }
                });
                return;
            }
            // buscamos is ya esta en algun item mas
            let contsample = 0;
            this.sampleList.forEach((element) => {
                // eslint-disable-next-line
                if (element.codesample == item.codesample) {
                    contsample += 1;
                }
            });
            if (contsample > 1) {
                this.sampleList[index].codesample = '';
                return;
            }
            // termina busqueda si esta repetido
            const idensayos = this.kitPCR.filter((word) => word.id === this.dataForm.kitPcr);
            const datasend = {
                sample_code: item.codesample,
                ensayoId: idensayos[0].id_ensayos,
            };
            if(this.infoUser.externo == 0) {
            const muestra = await Service.apiToken('POST', 'search-sample',
                this.$ls.storage.token, datasend);
            if (muestra.response === null) {
                this.snack.snackbar = true;
                this.snack.color = 'red';
                this.snack.txt = 'Muestra No Encontrada';
            } else
              {

                this.muestrasearch = muestra;
                if (muestra.response.publicado === 1) {
                    this.dialog = true;
                    return;
                }
                /// fin de la muestra
                this.snack.snackbar = true;
                this.snack.color = 'success';
                this.snack.txt = 'Muestra encontrada';

                this.sampleList.forEach((element) => {
                    if (element.codesample == item.codesample) {
                        element.icon = 'mdi-check-outline';
                        element.color = 'gren';
                        element.muestra_idres = muestra.response.id;
                        // element.matriz_id = muestra.muestra.id_matriz;
                        element.matriz_id = muestra.muestra.control == null ? muestra.muestra.id_matriz : muestra.muestra.control;
                        // element.matriznombre = muestra.kitmatriz !== null  ? muestra.kitmatriz.kit : ''
                        element.matriznombre = muestra.muestra.control == null ? muestra.kitmatriz !== null  ? muestra.kitmatriz.kit : '' :  muestra.muestra.control=='CP' ? 'Control Positivo' : 'Control Negativo';
                        // element.deteriorado = muestra.muestra.deteriorante;
                        element.deteriorado = muestra.muestra.control == null ? muestra.muestra.deteriorante : '';
                        element.matriz = muestra.muestra.matriz;
                        // element.kit = muestra.muestra.id_kit;
                        element.kit = muestra.muestra.control == null ? muestra.muestra.id_kit : 0;
                        element.info_muestra = muestra.muestra;
                        element.info_ensayo_muestra = muestra.ensayo_info;
                        element.checked = false;
                        element.local = ((muestra != null) ? muestra.muestra.ot_rel.local.nombre_local : '');
                    }
                });
            }
            }
        },
        checkFile(){
          console.log("check")
          console.log(this.dataForm.file)
          if(this.dataForm.file != null){
            console.log(this.dataForm.file.name.match(this.accept))
            if(this.dataForm.file.name.match(this.accept) == null){

              console.log("entra11",this.dataForm.file)
              this.dataForm.file = null;
              this.snack.snackbar = true;
              this.snack.color = 'red';
              this.snack.txt = 'Formato de archivo invalido, Ingrese un archivo con la extension '+this.accept_txt+'.';

            }

            /*if(this.accept.indexOf(this.dataForm.file.type)){
              console.log("entra1",this.dataForm.file)
              this.dataForm.file = null;
              this.snack.snackbar = true;
              this.snack.color = 'red';
              this.snack.txt = 'Formato de archivo invalido, Ingrese un archivo con la extension '+this.accept_txt+'.';
            }*/
          }
          if (this.dataForm.kitPcr) {
            this.activeinput = 1;
          }else{
            this.activeinput = 0;
          }

          if (this.dataForm.kitPcr && this.dataForm.file) {
            this.activarAnalisis = false;
          }else{
            this.activarAnalisis = true;
          }
        },
        async activarMuestras() {
          if (this.dataForm.kitPcr) {
            this.activeinput = 1;
          }else{
            this.activeinput = 0;
          }

          if (this.dataForm.kitPcr && this.dataForm.file) {
            this.activarAnalisis = false;
          }else{
            this.activarAnalisis = true;
          }

        },
        nopublicarMuestra() {
            this.sampleList.forEach((element) => {
                if (element.codesample == this.muestrasearch.response.muestras_id) {
                    element.icon = 'mdi-close';
                    element.color = 'red';
                    element.codesample = '';
                    element.muestra_idres = '';
                    element.matriz_id = 0;
                    element.matriznombre = '';
                    element.deteriorado = '';
                    element.matriz = '';
                    element.kit = 0;
                }
            });
            this.dialog = false;
        },
        publicarMuestra() {
            this.sampleList.forEach((element) => {
                if (element.codesample == this.muestrasearch.response.muestras_id) {
                    element.icon = 'mdi-check-outline';
                    element.color = 'gren';
                    element.muestra_idres = this.muestrasearch.response.id;
                    element.matriz_id = this.muestrasearch.muestra.id_matriz;
                    element.matriznombre = this.muestrasearch.kitmatriz !== null ? this.muestrasearch.kitmatriz.kit : ''
                    element.deteriorado = this.muestrasearch.muestra.deteriorante;
                    element.matriz = this.muestrasearch.muestra.matriz;
                    element.kit = this.muestrasearch.muestra.id_kit;
                    element.info_muestra = this.muestrasearch.muestra;
                    element.info_ensayo_muestra = this.muestrasearch.muestra;
                }
            });
            this.dialog = false;
        },
        async actualizaMuestra(item,event,tipo) {
            /*
            if (item.codesample !== '') {
                const info = {
                    id_muestra: item.codesample,
                    id_kit: item.kit,
                    deteriorado: item.deteriorado,
                }
                const update = await Service.apiToken('POST','update-sample',this.$ls.storage.token, info);
            }
            */

        },
        updatelist(item,event) {
            this.sampleList.forEach(element => {
                if (element.pocillo === item.pocillo) {
                    if (item.matriz_id != 12) {
                        delete element.deteriorado;
                        delete element.kit;
                        delete element.matriznombre
                    }
                    const filtermat = this.matrices.filter((matrizlist) => matrizlist.id === event);
                    element.matriz = filtermat[0].matriz;
                }
            });
        },
        async buscarOT(){
            if ( this.dataForm.OT ) {
                this.loadot = true;
                const info = {
                    ot: this.dataForm.OT,
                }

                if(this.infoUser.externo == 0) {
                  const OT = await Service.apiToken('POST', 'buscar-OT', this.$ls.storage.token, info);
                  if (OT.response.length == 0) {
                    this.snack.snackbar = true;
                    this.snack.color = 'red';
                    this.snack.txt = 'No se encontró datos con la OT ' + this.dataForm.OT;
                  } else {
                    // VERIFICA QUE LA OT TENGA MUESTRAS CON LOS KITS SELECCIONADOS
                    // console.log('verificar antes de abrir', this.kitPCR);
                    let found = false;
                    // RECORRO LOS KITS
                    this.kitPCR.map(kit => {
                      // RECORRO LAS MUESTRAS
                      if (!found) {
                        OT.response[0].muestras_ots.map(muestra => {
                          // LOS ENSAYOS DE ESTA MUESTRA
                          if (!found) {
                            muestra.ensayo_muestras.map((ensayoMuestra) => {
                              // LOS ENSAYOS DEL KIT
                              kit.id_ensayos.map((kitEnsayo) => {
                                if (kitEnsayo === ensayoMuestra.ensayo_id) {
                                  found = true;
                                }
                              });
                            });
                          }
                        })
                      }
                    })
                    if (found) {
                      this.$refs.detalleOt.open(OT.response[0], this);
                    } else {
                      this.snack.snackbar = true;
                      this.snack.color = 'red';
                      this.snack.txt = 'No hay muestras con algún kit Steriplex en la OT ' + this.dataForm.OT;
                    }
                  }
                }
                this.loadot = false;
            }
        },
        aplicarmatriz(item){
            this.sampleList.forEach(element => {
                if (element.checked == true && !element.codesample){
                    element.matriz_id = item.id;
                }
            });
        },
        aplicarKit(item){
            console.log(item);
            this.sampleList.forEach(element => {
                if (element.checked == true && element.matriz_id == 12 && !element.codesample){
                    element.kit = item.id;
                    element.matriznombre = item.kit;
                }
            });
        },
        aplicarDeterioro(item){
          this.sampleList.forEach(element => {
            if (element.checked == true && element.matriz_id == 12 && !element.codesample){
              element.deteriorado = item.id;
            }
          });
        },
        async analizarMuestras(muestras){

              //await this.limpiarplanilla();


            // console.log('muestras',muestras);
            let ensayoskit = [];
            this.kitPCR.forEach(element => {
               if (element.id == this.dataForm.kitPcr){
                   ensayoskit = element;
               }
            });
            console.log('kit',ensayoskit.id_ensayos);
            this.sampleList.forEach((element) => {
                muestras.forEach(elementM => {
                    if (element.pocillo == elementM.pocillo) {
                        // console.log(element);
                        //buscamos el ensayo que se esta analizando de la lista
                        let ensayoanalizar = [];
                        console.log("for elemnt")
                        console.log(elementM)
                        if (elementM.muestra != null){
                            elementM.muestra.ensayo_muestras.forEach(ensayoslist => {
                            if (ensayoskit.id_ensayos.includes(ensayoslist.ensayo_id)){
                                ensayoanalizar = ensayoslist;
                            }
                        });
                            // console.log('publicar', ensayoanalizar);

                            element.codesample = elementM.muestra.id;
                            element.icon = 'mdi-check-outline';
                            element.color = 'gren';
                            // element.muestra_idres = elementM.muestra.ensayo_muestras[0].id;
                            element.muestra_idres = ensayoanalizar.id;
                            // element.matriz_id = elementM.muestra.id_matriz;
                            element.matriz_id = elementM.muestra.control == null ? elementM.muestra.id_matriz : elementM.muestra.control;
                            // element.matriznombre = elementM.muestra.id_kit !== null  ? this.obtieneKit(elementM.muestra.id_kit) : ''
                            element.matriznombre = elementM.muestra.control == null ? elementM.muestra.id_kit !== null  ? this.obtieneKit(elementM.muestra.id_kit) : '' :  elementM.muestra.control=='CP' ? 'Control Positivo' : 'Control Negativo';
                            // element.deteriorado = elementM.muestra.deteriorante;
                            element.deteriorado = elementM.muestra.control == null ? elementM.muestra.deteriorante : '';
                            element.matriz = elementM.muestra.matriz;
                            // element.kit = elementM.muestra.id_kit;
                            element.kit = elementM.muestra.control == null ? elementM.muestra.id_kit : 0;
                            element.info_muestra = elementM.muestra;
                            // element.info_ensayo_muestra = elementM.muestra.ensayo_muestras[0];
                            element.info_ensayo_muestra = ensayoanalizar;
                            element.checked = false;
                            element.local = elementM.local;
                        }else{
                            element.matriz_id = elementM.control;
                            element.control = elementM.control;
                            element.matriz = (elementM.control == 'CN') ? 'Control Negativo' : 'Control Positivo';
                        }

                    }
                });

            });

        },
        obtieneKit(kitid){
            let kit = null;
            this.kitMatrices.forEach(element => {
                if (element.id == kitid){
                    kit = element.kit;
                }
            });
            return kit;
        },
        volver(){
            this.analizar = true;
            this.publicsample =false;
            this.dataForm = {};

            let planilla = [];
            this.arrayinicial = [1];
            this.arraycontinua = [2,3,4,5,6,7,8,9,10,11,12,13,14,15];
            this.nadaApublicar = false;
            this.parametroIndefinido = [];
            this.muestrasNopublicadas = [];
            this.local = '';
            for (let i = 1; i <= 16; i++) {
                let filaplanilla = {
                    pocillo: i,
                    nomMatriz: null,
                    codesample: '',
                    matriz_id: 0,
                    matriz: 'Otro',
                    kit: null,
                    Bac_1: null,
                    Bac_2: null,
                    Bac_3: null,
                    Bac_4: null,
                    Bac_5: null,
                    prob_1: null,
                    prob_2: null,
                    prob_3: null,
                    prob_4: null,
                    prob_5: null,
                    checked: false,
                    icon: 'mdi-close',
                    color: 'red',
                  local: '',
                  namePocillo: i+'',
                  deteriorado: null,
                }
                planilla.push(filaplanilla)
            }
            this.sampleList = planilla;
        },
        getclase(item){
            let clase = '';
            if(isNaN(parseInt(item.clase))){
            }
            else{
                if(item.outlier == 'NO'){
                    clase = '- Clase '+parseInt(item.clase)
                }
                if(item.outlier == 'Gris'){
                    clase = 'Zona gris - Clase '+parseInt(item.clase)
                }
            }
            return clase;
        },
        updatematriz(item,event) {
            this.sampleList.forEach(element => {
                if (element.pocillo === item.pocillo) {

                    const filtermat = this.kitMatrices.filter((matrizlist) => matrizlist.id === event);
                    element.matriznombre = filtermat[0].kit;
                }
            });
        },
        gettop: function () {
            this.intervalId = setInterval(() => {
                if (window.pageYOffset === 0) {
                clearInterval(this.intervalId)
                }
                window.scroll(0, window.pageYOffset - 50)
            }, 20)
        },
        getOrigen(data){
            let response = 'Otro';
            if(data.info_muestra){
                switch(data.info_muestra.mp_pt){
                    case 'mp': case 'mprima': response = 'Materia prima'; break;
                    case 'Pdeterminado':case 'Pterminado': response = 'Producto terminado'; break;
                    case null:
                        if(data.matriz_id==9){
                            response = data.matriz;
                        }
                        else if(data.matriz_id==7){
                            response = data.matriz;
                        }
                        else{
                            response = 'Otro';
                        }
                    break;
                    default: response = 'Otro'; break;
                }
            }
            else{
                switch(data.matriz_id){
                    case 9: response = data.matriz; break;
                    case 7: response = data.matriz; break;
                    default: response = 'Otro'; break;
                }
            }
            return response;
        },
        disableSelect(item){
            let disabled = false;
            if (item.info_muestra || item.matriz_id != 12){
                return true;
            }
        },
        async limpiarplanilla(){
            let filas = 16;
            let j = 1;
          let abc = ['']

          if(this.dataForm.equipoKit ==2){
               filas =12;
               abc = ['A','B','C','D','E','F','G','H']
            }
            let planilla = [];
            abc.forEach(function (index) {
            for (let i = 1; i <= filas; i++) {
                let filaplanilla = {
                    pocillo: j,
                    nomMatriz: null,
                    codesample: '',
                    matriz_id: 0,
                    matriz: 'Otro',
                    kit: null,
                    Bac_1: null,
                    Bac_2: null,
                    Bac_3: null,
                    Bac_4: null,
                    Bac_5: null,
                    prob_1: null,
                    prob_2: null,
                    prob_3: null,
                    prob_4: null,
                    prob_5: null,
                    checked: false,
                    icon: 'mdi-close',
                    color: 'red',
                  local: '',
                  namePocillo: ( (abc.length>1) ? index+i : i+''),
                  deteriorado: null,
                }
                planilla.push(filaplanilla)
              j++;
            }
            });
            this.sampleList = planilla;
        },
        getclasebac(item,pos){
            // console.log(['1tem',item,pos]);
            // console.log('tem',item[`Bac_${pos}`]);
            let result = '';

                switch (item[`Bac_${pos}`]) {
                    case ' ':
                    case ' ':
                        result = '';
                        break;
                    case 'Inhibido':
                        result = '';
                        break;
                    default:
                        result = (item[`Prob_${pos}`] == true) ? '- Positivo' : '';
                        break
                }

            return result;
        },
        async activaItemspocillos(item){
            console.log("eqipo "+ item);
            switch (item) {
                case 1:
                  this.accept_txt = "text/csv";
                  this.accept = /([a-zA-Z0-9\s_\\.\-\(\):])+(.csv)$/ig;
                  this.disabledFile = false;

                  break;
                case 2:
                  this.accept_txt = "text/plain";
                  this.accept = /([a-zA-Z0-9\s_\\.\-\(\):])+(.txt)$/ig;
                  this.disabledFile = false;

                  break;
                  default:
                    this.disabledFile = true;

            }
            let infoEquipo  = this.equipoKit.filter(function(element){
                return element.id == item;
            });
            this.dataForm.file = null;
          await this.limpiarplanilla();
        },

        async activaItemspocillosAbecedario(){

            let abc = ['A','B','C','D','E','F','G','H']

            let j =0;
            let planilla = [];
            abc.forEach(function (index) {
            for (let i = 1; i <= 12; i++) {
                let filaplanilla = {
                pocillo: index+i,
                nomMatriz: null,
                codesample: '',
                matriz_id: 0,
                matriz: 'Otro',
                kit: null,
                Bac_1: null,
                Bac_2: null,
                Bac_3: null,
                Bac_4: null,
                Bac_5: null,
                prob_1: null,
                prob_2: null,
                prob_3: null,
                prob_4: null,
                prob_5: null,
                checked: false,
                icon: 'mdi-close',
                color: 'red',
                local: '',
                namePocillo: index+i,
                deteriorado: null,
                }
                planilla.push(filaplanilla);
                j++;
            }
            });
            //console.log(planilla)

            this.sampleList = planilla;
        },
        currentDate() {
          const current = new Date();
          const date = `${current.getFullYear()}-${('0' + (current.getMonth() + 1)).slice(-2)}-${('0' + current.getDate()).slice(-2)}`;
          return date;
        },
        currentTime() {
          const today = new Date();
          const time = today.getHours() + ":" + today.getMinutes() + ":" + ('0' + today.getSeconds()).slice(-2);
          return time;
        }
    },
};
</script>
